import React from 'react';
import styled from 'styled-components';
import {
	ErrorMessage,
	Field,
	useFormikContext,
	useFormik,
	FieldArray,
} from 'formik';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import { Grid, Divider } from 'semantic-ui-react';
import { Images } from 'react-bootstrap-icons';

const UploadStyled = styled.div`
	.formTitle {
		text-align: center;
		padding-top: 1em;
		color: #e93644;
		font-weight: 700;
		font-size: var(--font-size-md);
	}
	.contactField {
		padding: 0.5em;
		width: 100%;
		border-radius: 4px;
		border-color: #ccc;
	}

	.title {
		font-weight: 700;
		padding: 0.5em 0;
		display: block;
		text-align: center;
	}

	.uploadBtn {
		/* background-color: #000; */
		color: #fff;
		cursor: pointer;
		padding: 0.5em;
		margin: 0 auto;
		display: block;
		border-radius: 3px;
		font-weight: 500;
		/* font-size: var(--font-size-base); */
	}
	.uploadContainer {
		border: 1px solid #ccc;
		border-radius: 1em;
		padding: 2em;
		background-color: #021d49;
		padding: 1em 0em;
	}
	.uploadSubtext {
		color: #8d8b8b;
		font-size: 0.9em;
		margin: 0.5em auto;
		text-align: center;
		margin-top: 1em;
	}

	.remove {
		background-color: black;
		color: white;
		border-radius: 50%;
		width: 1.5em !important;
		height: 1.5em !important;
		position: absolute;
		cursor: pointer;
		margin-top: -1em;
		margin-left: -0.5em;
		display: block;
		padding: 0em !important;
		align-content: center;
		display: grid;
	}
	.removeContainer {
		float: right;
	}

	.radiobtngroup {
		text-align: left;
		font-size: var(--font-size-base);
		font-weight: 700;
	}
	.sectorlbl {
		padding-left: 2em;
	}
	.radioItem {
		padding: 0.3em 1em;
	}
	/* Hide the browser's default radio button */
	.radioItem input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		/* top: 0;
		left: 0; */
		height: 25px;
		width: 25px;
		background-color: #fff;
		border-radius: 50%;
		border: 2px solid #021d49;
	}

	/* On mouse-over, add a grey background color */
	.radioItem:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the radio button is checked, add a blue background */
	.radioItem input:checked ~ .checkmark {
		background-color: #fff;
	}

	/* Create the indicator (the dot/circle - hidden when not checked) */
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the indicator (dot/circle) when checked */
	.radioItem input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the indicator (dot/circle) */
	.radioItem .checkmark:after {
		top: 5px;
		left: 5px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: #021d49;
	}
`;

export default function UploadDetails(props) {
	// const formikProps = useFormikContext();
	const { values } = useFormikContext();
	const uploadOptions = {
		tags: ['userVideos'],
		cloud_name: 'tektoniclabs',
		upload_preset: 'cityhandyman',
		folder: 'cityhandyman',
		cropping: false,
		sources: ['camera', 'local'],
		showPoweredBy: false,
		styles: {
			palette: {
				window: '#fff',
				windowBorder: '#90A0B3',
				tabIcon: '#0E2F5A',
				menuIcons: '#5A616A',
				textDark: '#000000',
				textLight: '#FFFFFF',
				link: '#000',
				action: '#FF620C',
				inactiveTabIcon: '#0E2F5A',
				error: '#F44235',
				inProgress: '#0078FF',
				complete: '#20B832',
				sourceBg: '#E4EBF1',
			},
		},
		// thumbnails: '#uploadedimage',
	};

	// const showWidget = () => {
	// 	const widget = window.cloudinary.createUploadWidget(
	// 		uploadOptions,
	// 		(error, result) => {
	// 			if (!error && result && result.event === 'success') {
	// 				console.log(result);
	// 				formikProps.setFieldValue('imageURL', result.info.url);
	// 			}
	// 		}
	// 	);
	// 	widget.open();
	// };

	return (
		<UploadStyled>
			{/* <div className='title'>Upload photos or videos</div> */}
			<h5 className='formTitle'>Tell us more...</h5>
			<Row xs={1} md={1} lg={1} xl={2} className='mt-5'>
				<Col>
					<Field
						as='textarea'
						rows='10'
						// cols='40'
						style={{ height: 'auto', width: '95%' }}
						id='jobDescription'
						name='jobDescription'
						placeholder='Describe your job'
					/>
				</Col>
				<Col>
					<Row className='mx-3'>
						<div className='uploadContainer'>
							<FieldArray name='imageURL'>
								{({ push, remove }) => (
									<>
										{/* <div className='uploadSubtext'>
											Accepted formats: JPG, JPEG, PNG, MP4, AVI
										</div> */}
										<div
											className='uploadBtn'
											// onClick={showWidget}
											onClick={() =>
												window.cloudinary
													.createUploadWidget(
														uploadOptions,
														(error, result) => {
															if (
																!error &&
																result &&
																result.event === 'success'
															) {
																console.log(result);
																// push(result.info.thumbnail_url);
																// created a new object with all the required details of the uploaded media item
																push(result.info);
																props.setMediaURL((oldValue) => [
																	...oldValue,
																	result.info.secure_url,
																]);
															}
														}
													)
													.open()
											}
											type='button'
										>
											Click here to upload a video / photos
											<Images className='m-3' color='red' size={45} />
										</div>
										{/* <Divider hidden></Divider> */}
										<Row className='m-1'>
											{values.imageURL.length > 0
												? values.imageURL.map((item, index) => {
														return (
															<>
																<Col
																	xs={4}
																	sm={2}
																	md={2}
																	lg={2}
																	className='m-2'
																>
																	<div className='removeContainer'>
																		<button
																			className='remove'
																			onClick={() => {
																				// Call the serverless function
																				// to delete the file in cloudinary
																				fetch(`/api/deleteMedia`, {
																					method: `POST`,
																					body: JSON.stringify(item),
																				}).then((res) => res.json());
																				remove(index);
																			}}
																			type='button'
																		>
																			x
																		</button>
																	</div>
																	<img
																		src={item.url}
																		width='100%'
																		alt='thumbnail'
																	/>
																</Col>
															</>
														);
												  })
												: null}
										</Row>
									</>
								)}
							</FieldArray>
							<div id='uploadedimage'></div>
						</div>
					</Row>
					<Row className='mt-5'>
						<div
							role='group'
							aria-labelledby='my-radio-group'
							className='radiobtngroup'
						>
							<label className='radioItem'>
								<Field type='radio' name='bookQuote' value='Book' />
								<span className='checkmark'></span>
								<span className='sectorlbl'>Book a Handyman</span>
							</label>
							<br></br>
							<label className='radioItem'>
								<Field type='radio' name='bookQuote' value='Quote' />
								<span className='checkmark'></span>
								<span className='sectorlbl'>
									Get a Quick Estimate or Quotation
								</span>
							</label>
							<ErrorMessage className='error' component='div' name='sector' />
						</div>
					</Row>
				</Col>
			</Row>
		</UploadStyled>
	);
}

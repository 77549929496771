import * as React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
// import Flag from '../../images/flag.jpg';

const AcknowledgementStyled = styled.div`
	margin-bottom: 1rem;

	.acknowledgement {
		padding: 0.5em;
		margin-top: 2rem;
	}
	p {
		margin-top: 1rem;
	}

	.flag {
		display: flex;
		justify-content: left;
		align-items: center;
	}

	.acknowledgementText {
		text-align: left;
		margin-left: 1rem;
	}

	// mobile settings
	@media (max-width: 768px) {
		.flag {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.acknowledgementText {
			text-align: center;
			margin-left: 0;
		}
	}
`;

export default function Acknowledgement() {
	return (
		<AcknowledgementStyled>
			<div className='acknowledgement'>
				<Container>
					<Row>
						{/* Column 1 */}
						{/* <Col xs={12} md={2} lg={1} className='flag'>
							<img alt='Australian Aboriginal Flag' src={Flag} />{' '}
						</Col> */}

						{/* Column 2 */}

						<Col xs={12} md={10} lg={12}>
							<p className='acknowledgementText'>
								City Handyman acknowledges the Traditional Owners of the lands
								where we live, learn and work. We pay our respects to Elders
								past and present and celebrate the stories, culture and
								traditions of all First Nations people.
							</p>
						</Col>
					</Row>
				</Container>
			</div>
		</AcknowledgementStyled>
	);
}

import { Link } from 'gatsby';
import { default as React } from 'react';
import { Highlight, Index, Snippet, useHits } from 'react-instantsearch';
import LinkComponent from '../../utils/linkComponent';

const PageHit = (props) => {
	const { hits } = useHits(props);
	return (
		<>
			{hits.map((hit) => (
				<div key={hit.objectID}>
					<LinkComponent
						to={`${hit.slug}`}
						id={hit.objectID}
						onClick={() => {
							window.analytics.track('Search Result Clicked', {
								title: 'Search Result Clicked',
								action: 'Clicked',
								location: window.location.href,
							});
						}}
					>
						<p style={{ marginBottom: '0.6rem' }}>
							<Highlight attribute='name' hit={hit} tagName='mark' />
						</p>
					</LinkComponent>
					<Snippet attribute='excerpt' hit={hit} tagName='mark' />
				</div>
			))}
		</>
	);
};
const EventHit = (props) => {
	const { hits } = useHits(props);
	return (
		<>
			{hits.map((hit) => (
				<div key={hit.objectID}>
					<LinkComponent
						to={`${hit.slug}`}
						id={hit.objectID}
						onClick={() => {
							window.analytics.track('Event Clicked', {
								title: hit.name,
								action: 'Clicked',
								location: window.location.href,
							});
						}}
					>
						<p style={{ marginBottom: '0.6rem' }}>
							<Highlight attribute='name' hit={hit} tagName='mark' />
						</p>
					</LinkComponent>
					<Snippet attribute='excerpt' hit={hit} tagName='mark' />
				</div>
			))}
		</>
	);
};
const ResourceHit = (props) => {
	const { hits } = useHits(props);
	return (
		<>
			{hits.map((hit) => (
				<div key={hit.objectID}>
					<LinkComponent
						to={`${hit.slug}`}
						id={hit.objectID}
						onClick={() => {
							window.analytics.track('Resource Card Clicked', {
								title: hit.name,
								action: 'Clicked',
								location: window.location.href,
							});
						}}
					>
						<p style={{ marginBottom: '0.6rem' }}>
							<Highlight attribute='name' hit={hit} tagName='mark' />
						</p>
					</LinkComponent>
					<Snippet attribute='excerpt' hit={hit} tagName='mark' />
				</div>
			))}
		</>
	);
};

const HitsInIndex = ({ index }) => (
	<Index indexName={index.name}>
		<h5 style={{ color: 'var(--primary)' }}>{index.title}</h5>
		{/* <HitCount /> */}
		{index.name === 'Resources' ? (
			<ResourceHit className='Hits' />
		) : index.name === 'Pages' ? (
			<PageHit className='Hits' />
		) : 
		// index.name === 'Events' ? (
		// 	<EventHit className='Hits' />
		// ) :
		 null}
	</Index>
);

const SearchResult = ({ indices, className }) => (
	<div className={className}>
		{indices.map((index) => (
			<HitsInIndex index={index} key={index.name} />
		))}
	</div>
);
export default SearchResult;

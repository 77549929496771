import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import React, { useState } from 'react';
import { navigate, Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import Search from '../Search';
import SubMenu from './subMenu';
import ImageComponent from '../../utils/imageComponent';
import ButtonComponent from '../../utils/ButtonComponent';
import LinkComponent from '../../utils/linkComponent';
const searchIndices = [
	{ name: `Pages`, title: `Pages` },
	{ name: `Resources`, title: `Resources` },
	// { name: `Events`, title: `Events` },
];
const StyledLink = styled((props) => <LinkComponent {...props} />)`
	font-size: var(--font-size-base);
	margin: 0em 0.7em;
	/* color: var(--secondary_2) !important; */
	padding: 10px 0px;
	color: var(--secondary_2);
	text-decoration: none;
	white-space: nowrap;
	align-self: center;
	// mobile settings
	@media (max-width: 1199.5px) {
		font-weight: 600;
		margin-left: 0em;
		align-self: start;
		.mainMenu {
			top: 0px !important;
		}
	}
`;

const NavStyled = styled.div`
	position: sticky;
	top: -1px;
	z-index: 1000;

	.navbar-light .navbar-nav .nav-link {
		color: var(--secondary_2) !important;
		font-size: var(--font-size-base);
	}

	.mainMenu {
		background: var(--secondary_6);
		position: sticky;
		top: 0;
	}

	.navbar-light .navbar-brand {
		color: var(--secondary_6);
	}

	.navbar-light .navbar-toggler {
		border-color: var(--secondary_7) !important;
		color: var(--secondary_2) !important;
	}
	// .navbar-toggler-icon {
	// 	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	// }

	// tablets settings
	@media (max-width: 1199.5px) {
		.navItem {
			border-bottom: 1px solid #ccc !important;
			border-radius: 0px;
			padding: 10px 0px;
			width: 100%;
		}
	}
	// mobile settings
	@media (max-width: 768px) {
		.navItem {
			border-bottom: 1px solid #ccc !important;
			border-radius: 0px;
			padding: 10px 0px;
			width: 100%;
		}
	}
`;
const Header = () => {
	const [expanded, setExpanded] = useState(false);
	const menuDataAndLogoData = useStaticQuery(graphql`
		query menuQueryAndStarpiAssetsLogo {
			strapiAsset {
				logo {
					alternativeText
					caption
					url
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
			allStrapiNavigationPluginMainNavigation(sort: { order: ASC }) {
				nodes {
					items {
						path
						title
						type
						sub_menu_icon
						items {
							path
							title
							type
						}
					}
					order
					title
					type
					path
				}
			}
		}
	`);
	return (
		<NavStyled>
			<Navbar
				variant='light'
				fixed='top'
				expand='xl'
				className='mainMenu'
				collapseOnSelect
				expanded={expanded}
				onToggle={() => setExpanded((expanded) => !expanded)}
			>
				<Container>
					<Navbar.Brand
						onClick={() => {
							setExpanded(false);
							navigate('/');
						}}
						style={{ cursor: 'pointer', width: '200px' }}
					>
						<ImageComponent
							imageItem={menuDataAndLogoData.strapiAsset.logo}
							alt='Logo'
						></ImageComponent>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ms-auto'>
							<Search setExpanded={setExpanded} indices={searchIndices} />
							{/* Adding a multilevel navbar with dropdowns */}
							{menuDataAndLogoData.allStrapiNavigationPluginMainNavigation.nodes.map(
								(menuItem) =>
									menuItem.items?.length > 0 ? (
										<SubMenu
											setExpanded={setExpanded}
											menuItem={menuItem}
										></SubMenu>
									) : (
										<StyledLink
											onClick={() => setExpanded(false)}
											className='navItem'
											to={menuItem.path}
											key={menuItem.title}
										>
											{menuItem.title}
										</StyledLink>
									)
							)}
							<ButtonComponent buttonName='Enquire Now'></ButtonComponent>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</NavStyled>
	);
};
export default Header;

import { ErrorMessage, Field, useFormikContext, useFormik } from 'formik';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const ThankyouNoteStyled = styled.div`
	.error {
		color: red;
	}
`;

export default function ThankyouNote(props) {
	// fetch the formik state values
	const { values } = useFormikContext();
	return (
		<ThankyouNoteStyled>
			{/* <Modal.Body> */}
			<h2
				style={{
					color: '#e73842',
					textAlign: 'center',
					fontFamily: 'MontserratVariable,sans-serif',
					fontWeight: '500',
				}}
			>
				Thank you.
			</h2>
			<div
				style={{
					color: '#1e4b95',
					textAlign: 'center',
					fontFamily: 'MontserratVariable,sans-serif',
					fontSize: 'larger',
					paddingTop: '2rem',
					fontWeight: '500',
				}}
			>
				Please check your email <br></br> {values.email} <br></br> for
				confirmation.
			</div>
			<div
				className='my-5'
				onClick={() => props.setOpen(false)}
				style={{
					color: '#e73842',
					fontWeight: '600',
					fontSize: '1.1em',
					textDecorationLine: 'underline',
					cursor: 'pointer',
				}}
			>
				Keep browsing
			</div>
			{/* </Modal.Body> */}
		</ThankyouNoteStyled>
	);
}

import { ErrorMessage, Field, useFormikContext, useFormik } from 'formik';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const InitialNoteStyled = styled.div`
	.contactField {
		padding: 0.5em;
		width: 100%;
		border-radius: 4px;
		border-color: #ccc;
	}

	.contactFieldContainer {
		font-weight: 700;
		width: 47%;
		margin: 0.5em;
		display: inline-block;
		padding: 0.5em 0;
	}

	.contactFieldAddress {
		font-weight: 700;
		width: 96%;
		margin: 0.5em;
		display: inline-block;
		padding: 0.5em 0;
	}

	.contactFieldTitle {
		margin-bottom: 0.5em;
	}
	.error {
		color: red;
	}

	// mobile settings
	@media (max-width: 768px) {
		.contactFieldContainer {
			width: 100%;
			margin: 0em;
			padding: 0.5em 0;
		}
		.contactFieldAddress {
			width: 100%;
			margin: 0em;
		}
	}
`;

export default function InitialNote(props) {
	return (
		<InitialNoteStyled>
			{/* <Modal.Body> */}
			<h2
				style={{
					color: '#e73842',
					textAlign: 'center',
					fontFamily: 'MontserratVariable,sans-serif',
					fontWeight: '500',
				}}
			>
				Please note
			</h2>
			<div
				style={{
					color: '#1e4b95',
					textAlign: 'center',
					fontFamily: 'MontserratVariable,sans-serif',
					fontSize: 'larger',
					paddingTop: '2rem',
					fontWeight: '500',
				}}
			>
				We currently service Melbourne CBD and most inner suburbs. But don't
				worry if you're further out, just drop us a line and we'll let you know
				if we can get to you.
			</div>
			{/* </Modal.Body> */}
		</InitialNoteStyled>
	);
}

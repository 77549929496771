import React from 'react';
import styled from 'styled-components';
import { useFormikContext, Field, ErrorMessage } from 'formik';
// import { Grid, Divider } from 'semantic-ui-react';
import { useStaticQuery, graphql } from 'gatsby';
import { mapEdgesToNodes } from '../../lib/helpers';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const ReviewStyled = styled.div`
	margin: 0em 2em;
	font-weight: 500;
	hr {
		color: #021d49;
		border-top: 2px solid;
		opacity: 1;
	}
	.reviewTitle {
		text-align: left;
		font-weight: 700;
		font-size: var(--font-size-md);
	}
	.reviewCol {
		text-align: left;
		/* margin-top: 1.3em; */
	}
	.formTitle {
		text-align: center;
		padding-top: 1em;
		color: #e93644;
		font-weight: 700;
		font-size: var(--font-size-md);
	}
	.contactFieldTitle {
		font-weight: 700;
		padding: 0em 0;
	}
	.contactFieldAddress {
		font-weight: 700;
		width: 96%;
		margin: 0.5em;
		display: inline-block;
		padding: 0.5em 0;
	}
	.contactField {
		padding: 0.5em;
		width: 100%;
		border-radius: 4px;
		border-color: #ccc;
	}
	// mobile settings
	@media (max-width: 768px) {
	}
`;

export default function ReviewDetails(props) {
	const { address, division } = props;
	// fetch the formik state values
	const { values } = useFormikContext();
	// console.log(values);
	return (
		<ReviewStyled>
			<h5 className='formTitle'>Review and submit</h5>
			<hr></hr>
			<Row className='mt-4 g-1'>
				<div className='reviewTitle'>Your details</div>
				<Col xs={12} md={6} lg={4} className='reviewCol'>
					<div className='subTitle mt-3'>Name:</div>
					<div>
						{values.firstName} {values.lastName}
					</div>
				</Col>
				<Col xs={12} md={6} lg={4} className='reviewCol'>
					<div className='subTitle mt-3'>Contact phone:</div>
					<div>{values.mobile}</div>
				</Col>
				<Col xs={12} md={6} lg={4} className='reviewCol'>
					<div className='subTitle mt-3'>Email:</div>
					<div>{values.email}</div>
				</Col>{' '}
				{values.companyName && (
					<Col xs={12} md={6} lg={4} className='reviewCol'>
						<div className='subTitle mt-3'>Company:</div>
						<div>{values.companyName}</div>
					</Col>
				)}
			</Row>
			<hr></hr>
			<Row className='mt-4 g-1'>
				<div className='reviewTitle'>Job address</div>
				<Col xs={12} md={6} lg={4} className='reviewCol'>
					{/* <div className='subTitle'>Address:</div> */}
					<div>{address}</div>
				</Col>
			</Row>
			<hr></hr>
			<Row className='mt-4 g-1'>
				<div className='reviewTitle'>Job details</div>
				<Col xs={12} md={12} lg={12} className='reviewCol'>
					<div
						className='subTitle'
						style={{ color: '#e93644', fontWeight: 600 }}
					>
						{division}
					</div>
					<div>{values.jobDescription}</div>
				</Col>
				<Row className='m-1'>
					{values.imageURL.length > 0
						? values.imageURL.map((item, index) => {
								return (
									<>
										<Col className='m-2' sm={7} md={3} lg={2}>
											<img src={item.url} width='100%' alt='thumbnail' />
										</Col>
									</>
								);
						  })
						: null}
				</Row>
			</Row>
			{/* <Grid>
				<Grid.Row>
					<Grid.Column mobile={15} computer={4}>
						<div className='contactFieldTitle'>Sector</div>
					</Grid.Column>
					<Grid.Column mobile={15} computer={11}>
						<div style={{ marginBottom: '1em' }}>
							<strong>{values.sector}</strong>
						</div>
						<Grid>
							{values.imageURL.length > 0
								? values.imageURL.map((item) => {
										return (
											<Grid.Column computer={3} mobile={7} tablet={3}>
												<img
													src={item.thumbnail_url}
													width='100px'
													alt='thumbnail images'
												/>
											</Grid.Column>
										);
								  })
								: null}
						</Grid>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column mobile={15} computer={4}>
						<div className='contactFieldTitle'>Job Details</div>
					</Grid.Column>
					<Grid.Column mobile={15} computer={11}>
						<div>
							{Object.entries(activeServices).map(([key, value]) => (
								<div key={key}>
									<div>
										<b>{key}</b>
									</div>
									<div>
										{value.map((answers) => (
											<div key={answers}>{answers}</div>
										))}
									</div>
									<br></br>
								</div>
							))}
						</div>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column mobile={15} computer={4}>
						<div className='contactFieldTitle'>Provided By</div>
					</Grid.Column>
					<Grid.Column mobile={15} computer={11}>
						<div>{division}</div>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column mobile={15} computer={4}>
						<div className='contactFieldTitle'>Contact Details</div>
					</Grid.Column>
					<Grid.Column mobile={15} computer={11}>
						<div>
							<strong>
								{values.firstName} {values.lastName}
							</strong>
						</div>
						<div>{values.phone}</div>
						<div>{values.email}</div>
						<div>{props.address}</div>
					</Grid.Column>
				</Grid.Row>
			</Grid> */}
		</ReviewStyled>
	);
}

import { useStaticQuery, graphql, Script } from 'gatsby';
import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import BookingForm from './BookingForm';
import styled from 'styled-components';
import ImageComponent from '../../utils/imageComponent';
import BookingForm from './BookingForm';
import CustomContext from '../../../custom-context';

const ModalStyled = styled.div`
	font-family: MontserratVariable, sans-serif;
	align-self: center;
	.enquireBtn {
	}
	.bookingFormLogo {
		width: 10px;
		height: 11px;
	}

	// mobile settings
	@media (max-width: 990px) {
		align-self: auto;
		/* border-bottom: 1px solid #ccc !important; */
		border-radius: 0px;
		/* padding: 10px 0px; */
		.enquireBtn {
			margin: 0 auto;
			display: flex;
		}
	}
`;
function BookingModal(props) {
	const { open, setOpen } = useContext(CustomContext);
	const logoData = useStaticQuery(graphql`
		query strapiAssetsLogo {
			strapiAsset {
				logo {
					alternativeText
					caption
					url
					localFile {
						childImageSharp {
							gatsbyImageData(height: 90)
						}
					}
				}
			}
		}
	`);
	function displayMessage() {
		if (
			window.confirm(
				'Exiting now will discard your job request details. Are you sure you want to quit?'
			)
		) {
			// They clicked Yes
			setOpen(false);
		} else {
			// They clicked no
		}
	}

	return (
		<ModalStyled>
			<Modal
				id='bookingModal'
				show={open}
				// fullscreen='xxl-down'
				fullscreen={true}
				centered={true}
				onHide={() => displayMessage()}
				// dialogClassName='modal-100w'
			>
				{/* <Modal.Header closeButton={false}>
					<Modal.Title>
						<ImageComponent
							imageItem={logoData.strapiAsset.logo}
							alt='Logo'
							className='bookingFormLogo'
						></ImageComponent>
					</Modal.Title>
				</Modal.Header> */}
				<Modal.Body>
					<BookingForm onClose={displayMessage} setOpen={setOpen}></BookingForm>
				</Modal.Body>
			</Modal>
		</ModalStyled>
	);
}

export default BookingModal;

import '@fontsource/raleway';
import '@fontsource/heebo';
import '@fontsource/heebo/200.css';
import '@fontsource/heebo/300.css';
import '@fontsource/heebo/500.css';
import '@fontsource/heebo/600.css';
import '@fontsource/heebo/700.css';
import 'bootstrap/dist/css/bootstrap.min.css';
const { CustomProvider } = require('./custom-context');
const React = require('react');

export function wrapPageElement({ element, props }) {
	return <CustomProvider {...props}>{element}</CustomProvider>;
}

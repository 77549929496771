import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import BookingModal from '../components/BookingForm/BookingModal';
import CustomContext from '../../custom-context';
export default function ButtonComponent(props) {
	const {
		analyticsTrackTitle,
		analyticsTitle,
		windowLocationHref,
		buttonName,
		iconVisible,
	} = props;
	const { setOpen } = useContext(CustomContext);

	if (
		buttonName === 'Enquire Now' ||
		buttonName === 'Book here' ||
		buttonName === 'Book Now' ||
		buttonName === 'Enquire'
	) {
		return (
			<Button
				onClick={() => {
					setOpen(true);
					window.analytics.track('Enquire Now Button Clicked', {
						title: 'Booking Form',
						action: 'Clicked',
						location: window.location.href,
					});
				}}
				variant='danger'
				size='lg'
			>
				{props.buttonName ? props.buttonName : 'Enquire Now'}
			</Button>
		);
	} else
		return (
			<Button
				onClick={() =>
					window.analytics.track(analyticsTrackTitle, {
						title: analyticsTitle,
						action: 'Clicked',
						location: windowLocationHref,
					})
				}
			>
				{buttonName !== null ? buttonName : ''}{' '}
				{iconVisible ? <ChevronRight className='btn-icon' size={18} /> : ''}
			</Button>
		);
}

import { ErrorMessage, Field, useFormikContext, useFormik } from 'formik';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

const ContactStyled = styled.div`
	.formTitle {
		text-align: center;
		padding-top: 1em;
		color: #e93644;
		font-weight: 700;
		font-size: var(--font-size-md);
	}
	.contactField {
		padding: 0.5em;
		width: 100%;
		border-radius: 4px;
		border-color: #ccc;
	}

	.contactFieldContainer {
		font-weight: 700;
		width: 47%;
		margin: 0.5em;
		display: inline-block;
		/* padding: 0.5em 0; */
	}

	.contactFieldAddress {
		font-weight: 700;
		width: 96%;
		/* margin: 0.5em; */
		display: inline-block;
		/* padding: 0.5em 0; */
	}

	.radiobtngroup {
		text-align: center;
		font-size: var(--font-size-base);
		font-weight: 700;
	}
	.sectorlbl {
		padding-left: 2em;
	}
	.radioItem {
		padding: 0.3em 1em;
	}
	.contactFieldTitle {
		margin-bottom: 0.5em;
		text-align: left;
	}
	.error {
		color: red;
	}
	.subText {
		font-size: var(--font-size-base);
		color: #5c5c5c;
		font-weight: 500;
		text-align: center;
	}

	/* Hide the browser's default radio button */
	.radioItem input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		/* top: 0;
		left: 0; */
		height: 25px;
		width: 25px;
		background-color: #fff;
		border-radius: 50%;
		border: 2px solid #021d49;
	}

	/* On mouse-over, add a grey background color */
	.radioItem:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the radio button is checked, add a blue background */
	.radioItem input:checked ~ .checkmark {
		background-color: #fff;
	}

	/* Create the indicator (the dot/circle - hidden when not checked) */
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the indicator (dot/circle) when checked */
	.radioItem input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the indicator (dot/circle) */
	.radioItem .checkmark:after {
		top: 5px;
		left: 5px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: #021d49;
	}

	.companyNameDiv {
		width: 96%;
	}

	// mobile settings
	@media (max-width: 768px) {
		.contactFieldContainer {
			width: 100%;
			margin: 0em;
			padding: 0.5em 0;
		}
		.contactFieldAddress {
			width: 100%;
			margin: 0em;
		}
		.companyNameDiv {
			width: 100%;
		}

		.radioItem {
			padding: 0.4em 0.5em;
		}

		.contactFieldTitle {
			margin-bottom: 0.2em;
		}
	}
`;

export default function ContactDetails(props) {
	useEffect(() => {
		var script = document.createElement('script');
		script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
		script.async = true;
		script.onload = loadWidget;
		document.body.appendChild(script);
	}, []);

	const { setFieldValue } = useFormikContext();
	const loadWidget = () => {
		const widget = new window.AddressFinder.Widget(
			document.getElementById('address'),
			'7DLKTYCMEA3NHUJ8XGW6',
			'AU'
		);
		widget.on('result:select', (fullAddress, metaData) => {
			props.setLine1(fullAddress);
			props.setAddressMeta(metaData);
			setFieldValue('address', fullAddress);
		});
	};

	return (
		<ContactStyled>
			<h5 className='formTitle'>Your details</h5>
			<div
				role='group'
				aria-labelledby='my-radio-group'
				className='radiobtngroup'
			>
				<label className='radioItem'>
					<Field type='radio' name='sector' value='Residential' />
					<span className='checkmark'></span>
					<span className='sectorlbl'>Residential</span>
				</label>
				<label className='radioItem'>
					<Field type='radio' name='sector' value='Commercial' />{' '}
					<span className='checkmark'></span>
					<span className='sectorlbl'>Commercial</span>
				</label>
				<label className='radioItem'>
					<Field type='radio' name='sector' value='Real Estate' />{' '}
					<span className='checkmark'></span>
					<span className='sectorlbl'>Real Estate</span>
				</label>
				<ErrorMessage className='error' component='div' name='sector' />
			</div>

			<div className='contactFieldContainer'>
				<div className='contactFieldTitle'>
					<label htmlFor='firstName'>
						First Name<span style={{ color: 'red' }}> *</span>
					</label>
				</div>

				<Field
					autoComplete='given-name'
					component='input'
					id='firstName'
					name='firstName'
					placeholder='First Name'
					type='text'
					className='contactField'
				/>
				<ErrorMessage className='error' component='div' name='firstName' />
			</div>
			<div className='contactFieldContainer'>
				<div className='contactFieldTitle'>
					<label htmlFor='lastName'>
						Last Name <span style={{ color: 'red' }}> *</span>
					</label>
				</div>
				<Field
					autoComplete='family-name'
					component='input'
					id='lastName'
					name='lastName'
					placeholder='Last Name'
					type='text'
					className='contactField'
				/>
				<ErrorMessage className='error' component='div' name='lastName' />
			</div>
			<div className='contactFieldContainer'>
				<div className='contactFieldTitle'>
					<label htmlFor='email'>
						Email <span style={{ color: 'red' }}> *</span>
					</label>
				</div>

				<Field
					autoComplete='email'
					component='input'
					id='email'
					name='email'
					placeholder='Email'
					type='text'
					className='contactField'
				/>
				<ErrorMessage className='error' component='div' name='email' />
			</div>
			<div className='contactFieldContainer'>
				<div className='contactFieldTitle'>
					<label htmlFor='phone'>
						Mobile <span style={{ color: 'red' }}> *</span>
					</label>
				</div>
				<Field
					component='input'
					id='phone'
					name='mobile'
					placeholder='Mobile'
					type='tel'
					className='contactField'
				/>
				<ErrorMessage className='error' component='div' name='mobile' />
			</div>
			<div
				className='contactFieldContainer companyNameDiv'
				// style={{ width: '96%' }}
			>
				<div className='contactFieldTitle'>
					<label htmlFor='companyName'>Company (if applicable)</label>
				</div>
				<Field
					component='input'
					id='companyName'
					name='companyName'
					placeholder='Company Name (if applicable)'
					type='text'
					className='contactField'
				/>
				{/* <ErrorMessage className='error' component='div' name='mobile' /> */}
			</div>
			{/* <h5 className='formTitle'>Job address</h5> */}

			<div className='contactFieldAddress'>
				<div className='contactFieldTitle'>
					<label htmlFor='address'>
						Job Address <span style={{ color: 'red' }}> *</span>
					</label>
				</div>
				<Field
					component='input'
					required
					id='address'
					name='address'
					type='text'
					placeholder='Address'
					// value={props.line1}
					// onChange={props.handleChange}
					className='contactField'
				/>
				<ErrorMessage className='error' component='div' name='address' />
			</div>
			{/* <div className='subText'>
				Please note a service fee applies to all jobs.
			</div> */}
		</ContactStyled>
	);
}

import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Debug } from './Debug';
import styled from 'styled-components';
import InitialNote from './InitialNote';
import ContactDetails from './ContactDetails';
import ReviewDetails from './ReviewDetails';
import { navigate } from 'gatsby';
import Divisions from './Divisions';
import UploadDetails from './UploadDetails';
import ThankyouNote from './ThankyouNote';
import { Spinner } from 'react-bootstrap';

const FormStyled = styled.div`
	font-family: MontserratVariable, sans-serif;
	color: #021d49;
	background-color: white;
	padding: 0.5em 2em;
	.steps {
		text-align: center;
		font-weight: 600;
		font-size: 1.2em;
		margin-bottom: 1em;
	}
	.buttonContainer {
		align-items: center;
		justify-content: space-between;
		display: flex;
		margin: 0 auto;
	}
	.buttonControl {
		/* background-color: #000; */
		/* color: #fff; */
		padding: 0.6em;
		margin: 0.4em;
		cursor: pointer;
		width: 6.2em;
		font-size: var(--font-size-lg);
		background-color: #0000;
		border: 0px;
		color: #e93644;
		font-family: MontserratVariable, sans-serif;
		font-weight: 500;
	}

	.nextButtonControl:after {
		position: relative;
		/* top: 3pt; Uncomment this to lower the icons as requested in comments*/
		content: '';
		display: inline-block;
		/* By using an em scale, the arrows will size with the font */
		width: 0.6em;
		height: 0.6em;
		border-right: 0.11em solid #e93644;
		border-top: 0.11em solid #e93644;
		transform: rotate(45deg);
		margin-right: 0.5em;
	}

	.backButtonControl:before {
		position: relative;
		/* top: 3pt; Uncomment this to lower the icons as requested in comments*/
		content: '';
		display: inline-block;
		/* By using an em scale, the arrows will size with the font */
		width: 0.6em;
		height: 0.6em;
		border-right: 0.11em solid #e93644;
		border-top: 0.11em solid #e93644;
		transform: rotate(225deg);
		margin-right: 0.5em;
	}

	.closeDiv {
		text-align: right;
		display: inline-block;
		width: 50%;
	}

	.backDiv {
		display: inline-block;
		width: 50%;
	}

	.closeBtnDiv {
		text-align: right;
		width: 100%;
		font-size: var(--font-size-md);
	}

	.closeBtn {
		color: black;
		background-color: white;
		font-weight: 600;
		cursor: pointer;
		border: none;
	}

	.steps {
		font-family: MontserratVariable, sans-serif;
		font-size: var(--font-size-lg);
		margin-bottom: 0.5em;
		margin-top: -0.3em;
	}

	// mobile settings
	@media (max-width: 768px) {
		padding: 0.5em;
		.buttonControl {
			font-size: var(--font-size-md);
			font-weight: 600;
			padding: 0.6em 0.4em;
		}
		.closeBtn {
			margin-bottom: 1em !important;
		}
		.steps {
			margin-bottom: 0em;
			margin-top: -2em;
		}
	}
`;

const stepDesign = (step) => {
	return (
		<center>
			<div
				// style={{
				// 	fontFamily: 'MontserratVariable, sans-serif',
				// 	fontSize: 'var(--font-size-lg)',
				// 	marginBottom: '0.5em',
				// 	marginTop: '-0.3em',
				// }}
				className='steps'
			>
				<span
					style={{ color: '#021d49', fontWeight: '700', padding: '0em 0.25em' }}
				>
					Step
				</span>
				<span
					style={{
						color: step === 1 ? '#e73842' : '#b2b2b2',
						fontWeight: '700',
						padding: '0em 0.25em',
					}}
				>
					1
				</span>
				<span
					style={{
						color: step === 2 ? '#e73842' : '#b2b2b2',
						fontWeight: '700',
						padding: '0em 0.25em',
					}}
				>
					2
				</span>
				<span
					style={{
						color: step === 3 ? '#e73842' : '#b2b2b2',
						fontWeight: '700',
						padding: '0em 0.25em',
					}}
				>
					3
				</span>
				<span
					style={{
						color: step === 4 ? '#e73842' : '#b2b2b2',
						fontWeight: '700',
						padding: '0em 0.25em',
					}}
				>
					4
				</span>
			</div>
		</center>
	);
};

// Wizard is a single Formik instance whose children are each page of the
// multi-step form. The form is submitted on each forward transition (can only
// progress with valid input), whereas a backwards step is allowed with
// incomplete data. A snapshot of form state is used as initialValues after each
// transition. Each page has an optional submit handler, and the top-level
// submit is called when the final page is submitted.
const Wizard = ({
	line1,
	division,
	children,
	mediaURL,
	initialValues,
	onSubmit,
	closeModal,
	setOpen,
	addressMeta,
	isFormSubmitting,
	setIsFormSubmitting,
}) => {
	const [stepNumber, setStepNumber] = useState(0);
	const steps = React.Children.toArray(children);
	const [snapshot, setSnapshot] = useState(initialValues);
	const step = steps[stepNumber];
	const totalSteps = steps.length;
	const isLastStep = stepNumber === totalSteps - 2;

	const next = (values) => {
		//scroll to top of the page
		document.getElementById('bookingModal').scrollIntoView();
		setSnapshot(values);
		setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
	};

	const previous = (values) => {
		//scroll to top of the page
		document
			.getElementById('bookingModal')
			.scrollIntoView({ behavior: 'smooth' });
		setSnapshot(values);
		setStepNumber(Math.max(stepNumber - 1, 0));
	};

	const handleSubmit = async (values, bag) => {
		// console.log(values);
		if (step.props.onSubmit) {
			await step.props.onSubmit(values, bag);
		}
		if (isLastStep && !isFormSubmitting) {
			setIsFormSubmitting(true);
			// create a description field
			let Description =
				'\n';
			Description += 'DIVISION:\n';
			// console.log(Description);
			Description += `${division} + $75 callout\n\n`;
			Description += `\n`;
			Description += 'JOB DESCRIPTION:\n';
			Description += `${values.jobDescription}\n\n`;
			//Description += `<br></br>------------------------------------------------------<br></br><br></br>\n`;

			//console.log(Description);
			let Images_Videos = '';
			// for adding images
			if (values.imageURL.length > 0) {
				values.imageURL.forEach((image) => {
					if (image !== undefined)
						Images_Videos += `<img src="${image.secure_url}" alt="Job Request Media" width="300" height="100%"> \n
						`;
					Images_Videos += `${image.secure_url} \n`;
				});
			}

			//console.log(Images_Videos);
			//create a address object
			var City = line1.split(',');
			City = City[City.length - 1].split(' ');
			City.pop();
			City.pop();

			//book_quote variable from checkbox
			let Book_Quote =
				values.bookQuote === 'Book' ? 'Book a Handyman' : 'Request a Quote';
			let formData = {
				Book_Quote: Book_Quote,
				Description: Description,
				Images_Videos: Images_Videos,
				Email: values.email,
				First_Name: values.firstName,
				Last_Name: values.lastName,
				Phone: values.mobile,
				Promotional_Code: values.promocode,
				Type_of_Premises: values.sector,
				Street: values.address,
				Address_Line_2: '',
				City: addressMeta.locality_name,
				Zip_Code: values.address.substr(-4),
				Company: '',
				Division: division,
				Layout: {
					name: 'New Request Layout',
					id: '3777011000000271249',
				},
			};
			// console.log(formData);
			// Call the serverless function to create a booking request in Zoho
			fetch(`/api/createBookingRequest`, {
				method: `POST`,
				body: JSON.stringify(formData),
			})
				.then((res) => res.json())
				.then((body) => {
					if (body.data[0].status === 'success');
					// console.log(`response from API:`, body);
					// setApiCall(false);
					next(values);
					setIsFormSubmitting(false);
				});
			return onSubmit(values, bag);
		} else {
			bag.setTouched({});
			next(values);
		}
	};

	return (
		<Formik
			initialValues={snapshot}
			onSubmit={handleSubmit}
			validationSchema={step.props.validationSchema}
		>
			{(formik) => (
				<Form>
					<div className='closeBtnDiv'>
						<button
							onClick={() => {
								window.analytics.track(
									`Request Form - Step No. ${
										stepNumber + 1
									} Close Button Clicked`,
									{
										title: `Step No. ${stepNumber + 1}`,
										action: 'Clicked',
										location: window.location.href,
										properties: formik.values,
									}
								);
								stepNumber === 5 ? setOpen(false) : closeModal();
							}}
							className='closeBtn'
							type='button'
						>
							X
						</button>
					</div>

					{
						//Removed the logic bcoz we removed the "note" step
						// stepNumber === 0 ||
						stepNumber === 4 ? '' : stepDesign(stepNumber + 1)
					}
					{/* Displays the screens in Wizard Step component */}
					{step}

					{/* Displays the button as Save if job details screen is visible */}
					{stepNumber === 4 ? (
						''
					) : (
						<div
							className='buttonContainer'
							style={stepNumber === 0 ? { float: 'right' } : {}}
						>
							{stepNumber > 0 && (
								<button
									onClick={() => {
										window.analytics.track(
											`Request Form - Step No. ${
												stepNumber + 1
											} Back Button Clicked`,
											{
												title: `Step No. ${stepNumber + 1}`,
												action: 'Clicked',
												location: window.location.href,
											}
										);
										previous(formik.values);
									}}
									type='button'
									className='buttonControl backButtonControl'
								>
									Back
								</button>
							)}
							<div>
								<button
									disabled={isFormSubmitting}
									onClick={() => {
										window.analytics.track(
											`Request Form - ${
												isLastStep
													? 'Submit'
													: `Step No. ${stepNumber + 1} Next`
											} Button Clicked`,
											{
												title: `Step No. ${stepNumber + 1}`,
												action: 'Clicked',
												location: window.location.href,
											}
										);
									}}
									style={
										isFormSubmitting
											? { cursor: 'not-allowed' }
											: { cursor: 'pointer' }
									}
									type='submit'
									className='buttonControl nextButtonControl'
								>
									{isLastStep ? 'Submit' : 'Next '}
								</button>
							</div>
						</div>
					)}

					{/* <Debug /> */}
				</Form>
			)}
		</Formik>
	);
};

const WizardStep = ({ children }) => children;

export default function BookingForm(props) {
	// Address Line 1 below
	const [line1, setLine1] = useState('');
	const [division, setDivision] = useState('City Handyman Pro - $80/hr');
	const [addressMeta, setAddressMeta] = useState('');
	const [mediaURL, setMediaURL] = useState([]);
	const [isFormSubmitting, setIsFormSubmitting] = useState(false);

	// updating and displaying address and storing in state
	const line1Change = (event) => {
		// console.log(event.target.value);
		setLine1(event.target.value);
	};

	return (
		<FormStyled>
			<Wizard
				line1={line1}
				division={division}
				addressMeta={addressMeta}
				mediaURL={mediaURL}
				closeModal={props.onClose}
				setOpen={props.setOpen}
				onChange={props.handleChange}
				isFormSubmitting={isFormSubmitting}
				setIsFormSubmitting={setIsFormSubmitting}
				initialValues={{
					email: '',
					firstName: '',
					lastName: '',
					company: '',
					sector: 'Residential',
					jobDescription: '',
					phone: '',
					division: '',
					imageURL: [],
					bookQuote: 'Book',
					mediaURL: [],
				}}
				// onSubmit={async (values) => {
				// 	sleep(300).then(() => console.log('Wizard submit', values));
				// }}
			>
				{/* <WizardStep onSubmit={() => console.log('Step1 onSubmit')}>
					<InitialNote></InitialNote>
				</WizardStep> */}
				<WizardStep
					onSubmit={() => console.log('Step2 onSubmit')}
					validationSchema={Yup.object({
						firstName: Yup.string().required('Required'),
						lastName: Yup.string().required('Required'),
						email: Yup.string()
							.email('Invalid email address')
							.required('Required'),
						mobile: Yup.string().required('Required'),
						// check the validation for the address field and it needs to have atleast 4 digit pincode at the end
						// example of address string "30 Hoyts St, st kilda VIC 4222"
						address: Yup.string()
							.min(10)
							.test(
								'check-address',
								'Please enter a valid address',
								(value) => {
									console.log('value', value);
									// get the pincode from the value string
									const pincode = value?.split(' ').pop();
									console.log('pincode', pincode);
									if (pincode) {
										//check if the pincode is 4 numerical digit
										return /^\d{4}$/.test(pincode);
									}
									return false;
								}
							),
					})}
				>
					<ContactDetails
						handleChange={line1Change}
						line1={line1}
						setLine1={setLine1}
						addressMeta={addressMeta}
						setAddressMeta={setAddressMeta}
					/>
				</WizardStep>
				<WizardStep onSubmit={() => console.log('Step3 onSubmit')}>
					<Divisions
						validationSchema={Yup.object({
							division: Yup.string().required('Required'),
						})}
						setDivision={setDivision}
						division={division}
					></Divisions>
				</WizardStep>
				<WizardStep onSubmit={() => console.log('Step4 onSubmit')}>
					<UploadDetails setMediaURL={setMediaURL}></UploadDetails>
				</WizardStep>
				<WizardStep onSubmit={() => console.log('Step5 onSubmit')}>
					{isFormSubmitting ? (
						<div className='m-5 p-5'>
							<Spinner animation='border' variant='danger' />
							<h2>Submitting...</h2>
						</div>
					) : (
						<ReviewDetails address={line1} division={division} />
					)}
				</WizardStep>
				<WizardStep>
					<ThankyouNote setOpen={props.setOpen} />
				</WizardStep>
			</Wizard>
		</FormStyled>
	);
}

import React from 'react';

export function cn(...args) {
	return args.filter(Boolean).join(' ');
}

export function mapEdgesToNodes(data) {
	if (!data.edges) return [];
	return data.edges.map((edge) => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
	return (slug || {}).current;
}
export function generateSlugs(name) {
	return name.replace(/:/g, '').replace(/\s+/g, '-').toLowerCase();
}

export function LinkRenderer(props) {
	return (
		<a href={props.href} rel='noreferrer'>
			{props.children}
		</a>
	);
}

export function isExecutive(user) {
	return user.peopleRoles.filter((role) => role === 19).length > 0;
}

import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import moment from 'moment';
import { Container, Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
	faFacebook,
	faInstagram,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import LinkComponent from '../../utils/linkComponent';

const CopyrightStyled = styled.div`
	background-color: var(--secondary_1);
	color: var(--secondary_6);
	padding: 1em 3em;
	justify-content: center;
	font-size: 0.8em;
	text-align: left;
	/* margin-bottom: 2.7em; */

	a {
		color: var(--secondary_6) !important;
		margin-left: 0.3em;
		margin-right: 0.3em;
	}

	.social_media_icons {
		font-size: 2rem;
		padding-bottom: 0.5em;
	}
	.btnContainer {
		margin-top: 2rem;
	}
	.image {
		padding-left: 4rem;
	}

	.btn {
		font-size: var(--font-size-base) !important;
	}

	@media (max-width: 768px) {
		/* margin-bottom: 7em; */
		padding: 1em 1em;
		.image {
			padding-left: 1rem;
		}
	}
`;

const Footer = () => {
	const data = useStaticQuery(graphql`
		query FooterQuery {
			siteBuildMetadata {
				id
				buildTime
			}
		}
	`);
	return (
		<CopyrightStyled>
			<Container>
				<div className='social_media_icons'>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://www.facebook.com/cityhandyman3000'
					>
						<FontAwesomeIcon icon={faFacebook} />
					</a>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://www.instagram.com/cityhandyman/?hl=en'
					>
						<FontAwesomeIcon icon={faInstagram} />
					</a>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://www.linkedin.com/company/city-handyman/about'
					>
						<FontAwesomeIcon icon={faLinkedin} />
					</a>
				</div>
				{/* <div className='btnContainer'>
					<Link to='https://cityhandyman.com.au/contact/'>
						<Button variant='outline-dark'>
							Contact us
							<ChevronRight color='white' size={18} />
						</Button>
					</Link>
				</div> */}
			</Container>
			© Copyright {new Date().getFullYear()} City Handyman All Rights Reserved{' '}
			<LinkComponent to='/privacy-policy'>Privacy Policy -</LinkComponent>
			<LinkComponent to='/terms-and-conditions'>
				Terms and Conditions -
			</LinkComponent>
			{/* <Link to='/careers'>Careers - </Link> */}
			v1.0.0 on{' '}
			{moment(data.siteBuildMetadata.buildTime).format(
				'DD MMM YYYY - HH:mm:ss'
			)}{' '}
			- Site powered by
			<a href='http://www.tektoniclabs.io' target='_blank' rel='noreferrer'>
				Tektonic Labs
			</a>
			<img
				className='image'
				width='250'
				src='https://res.cloudinary.com/tektoniclabs/image/upload/v1674603707/CHM_logo_alt_1_aacf5f20a5.svg?updated_at=2023-01-24T23:41:48.732Z'
				alt='logo'
			></img>
		</CopyrightStyled>
	);
};
export default Footer;
